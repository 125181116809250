<template>
	<ul class="Gallery" ref="root">
		<li
			class="Gallery__box"
			v-for="({src, layout, size: [width, height]}, i) in gallery"
			:key="i"
			:class="layout"
		>
			<figure
				class="Gallery__wrapper"
				:style="{
					paddingTop: `calc(var(--img-scale) * ${(height / width) * 100}%)`,
					...galleryStyle[i],
				}"
			>
				<img v-lazy="src" @load="onImgLoaded" @click="$emit('popup', src)" />
			</figure>
		</li>
	</ul>
</template>

<script lang="ts">
import {templateRef, useCssVar} from '@vueuse/core'
import BezierEasing from 'bezier-easing'
import yaml from 'js-yaml'
import {computed, defineComponent, ref, watchEffect} from 'vue'

interface GalleryImage {
	src: string
	size: [number, number]
	layout?: 'small' | 'center' | 'large' | 'full'
}

export default defineComponent({
	name: 'Gallery',
	setup() {
		const root = templateRef('root')
		const gap = useCssVar('--gap', root)
		const imageScale = useCssVar('--img-scale', root)

		const gallery = ref<GalleryImage[]>([])

		const randomEasing = BezierEasing(0.4, 0, 0.6, 1)

		watchEffect(() => console.log(imageScale.value))

		fetch('/gallery.yml').then(async res => {
			gallery.value = yaml.loadAll(await res.text())[0].gallery
		})

		const galleryStyle = computed(() =>
			gallery.value.map(() => ({
				'--x': randomEasing(Math.random()),
				//marginLeft: Math.random() * (100 - parseFloat(imageScale.value)) + '%',
				animationDelay: Math.random() * -4 + 's',
			}))
		)

		function onImgLoaded(e: Event) {
			const el = e.target as HTMLImageElement
			if (el.src.startsWith('data')) return
			el.classList.add('loaded')
		}

		return {gallery, galleryStyle, gap, onImgLoaded}
	},
})
</script>

<style lang="stylus">
@import '../common.styl'

.Gallery
	display grid
	margin 0 auto
	padding calc(3 * var(--gap)) 0
	max-width 1440px
	column-gap var(--gap)
	grid-template-columns 1fr 1fr 1fr 1fr
	row-gap var(--gap)
	grid-auto-flow row
	--img-scale 0.8
	--gap 3rem

	+tablet()
		--gap 1.5rem
		--img-scale 0.9

	+mobile()
		grid-template-columns 1fr

	&__box
		position relative
		align-self center
		list-style none
		grid-column span 2

		// Add vertical offset
		&:nth-child(2n)
			transform translateY(calc(-0.25 * var(--gap)))

		&:nth-child(2n + 1)
			transform translateY(calc(0.25 * var(--gap)))

		+mobile()
			transform none

		// Size
		&.large, &.full
			transform none
			grid-column span 4

		&.center
			grid-column 2 / 4

		&.full, &.center
			--img-scale 1

		+mobile()
			transform none !important
			grid-column span 1 !important
			--img-scale 0.7

			&.large
				--img-scale 0.9

			&.center
				--img-scale 0.8

				.Gallery__wrapper
					--x 0.5 !important

	&__wrapper
		position relative
		left calc((1 - var(--img-scale)) * var(--x) * 100%)
		width calc(var(--img-scale) * 100%)
		transform-origin 0 0
		animate-bg()

	img
		position absolute
		top 0
		display block
		width 100%
		height 100%
		opacity 0
		cursor zoom-in
		transition opacity 0.2s ease 0.2s

		&.loaded
			opacity 1
</style>
