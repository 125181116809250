<template>
	<div class="App" :modal="modal && modal.type">
		<div class="App__frame"></div>
		<header class="App__header">
			<hgroup class="App__title">
				<h1>Momoko Hashimoto</h1>
				<h2>橋本 桃子</h2>
			</hgroup>
			<menu class="App__menu">
				<a :class="{modal: modal}" @click="onClickMenu" />
			</menu>
		</header>

		<main class="App__content">
			<Gallery @popup="popupImage" />
		</main>

		<svg class="App__filters" xmlns="http://www.w3.org/2000/svg">
			<filter id="duotone-raddish">
				<feColorMatrix
					type="matrix"
					result="grayscale"
					values="1 0 0 0 0 1 0 0 0 0 1 0 0 0 0 0 0 0 1 0"
				></feColorMatrix>
				<feComponentTransfer
					color-interpolation-filters="sRGB"
					result="duotone-raddish"
				>
					<feFuncR type="table" tableValues="0.8051 0.8916"></feFuncR>
					<feFuncG type="table" tableValues="0.866 0.4783"></feFuncG>
					<feFuncB type="table" tableValues="1 0.5862"></feFuncB>
					<feFuncA type="table" tableValues="0 1"></feFuncA>
				</feComponentTransfer>
			</filter>
			<filter id="duotone-black">
				<feColorMatrix
					type="matrix"
					result="grayscale"
					values="1 0 0 0 0 1 0 0 0 0 1 0 0 0 0 0 0 0 1 0"
				></feColorMatrix>
				<feComponentTransfer
					color-interpolation-filters="sRGB"
					result="duotone-black"
				>
					<feFuncR type="table" tableValues="0.8051 0"></feFuncR>
					<feFuncG type="table" tableValues="0.866 0"></feFuncG>
					<feFuncB type="table" tableValues="1 0"></feFuncB>
					<feFuncA type="table" tableValues="0 1"></feFuncA>
				</feComponentTransfer>
			</filter>
		</svg>
	</div>

	<teleport to="body">
		<div
			class="App__modal"
			:class="{'disable-svg-filter': disableSVGFilter}"
			ref="modalEl"
			:modal="modal && modal.type"
			@click="onClickModal"
		>
			<div class="App__modal-content">
				<template v-if="modal && modal.type === 'info'">
					<p>🦊 Mail 🦊</p>
					<p><a href="mailto:m@momoko.pictures">m@momoko.pictures</a></p>
				</template>
				<template v-else-if="modal && modal.type === 'image'">
					<img :src="modal.src" />
				</template>
			</div>
		</div>
	</teleport>
</template>

<script lang="ts">
import 'normalize.css'

import {templateRef} from '@vueuse/core'
import {defineComponent, ref} from 'vue'

import Gallery from './components/Gallery.vue'

function isMobileSafari() {
	return !!/iPad|iPhone/i.test(window.navigator.userAgent)
}

interface ModalInfo {
	type: 'info'
}

interface ModalImage {
	type: 'image'
	src: string
}

type ModalContent = null | ModalInfo | ModalImage

export default defineComponent({
	name: 'App',
	components: {Gallery},
	setup() {
		const disableSVGFilter = ref(isMobileSafari())

		const modal = ref<ModalContent>(null)

		const modalEl = templateRef('modalEl')

		function onClickMenu() {
			modal.value = modal.value ? null : {type: 'info'}
		}

		function onClickModal(e: Event) {
			if (!modal.value) return

			if (modal.value.type === 'info') {
				if (e.target === modalEl.value) {
					modal.value = null
				}
			} else {
				modal.value = null
			}
		}

		function popupImage(src: string) {
			modal.value = {type: 'image', src}
		}

		return {
			disableSVGFilter,
			modal,
			onClickMenu,
			onClickModal,
			popupImage,
		}
	},
})
</script>

<style lang="stylus">
@import 'common.styl'

@keyframes bg-anim
	from
		background-position-x $gradient-repeat-width

	to
		background-position-x 0%

*, ::after, ::before
	box-sizing border-box
	margin 0
	padding 0
	outline none
	border 0
	-webkit-tap-highlight-color transparentify($redpink, 0.2)

html
	font-size 18px
	font-family 'Krona One', Helvetica, Arial, a-otf-ryumin-pr6n, sans-serif

	+tablet()
		font-size 14px

html, body
	margin 0
	padding 0

button
	padding 0
	border 0
	background none
	font-size inherit
	appearance none

.App
	min-height 100vh
	background-color #eee
	color black
	text-align center
	transition background-color $modal-dur ease
	-webkit-font-smoothing antialiased
	-moz-osx-font-smoothing grayscale
	--frame-width 1.2rem

	+mobile()
		--frame-width 0.8rem

	&[modal]
		background-color white

	&__frame
		position fixed
		top 0
		z-index 10
		width 100%
		height 100%
		border-width var(--frame-width)
		border-style solid
		border-image linear-gradient(-135deg, $redpink, #F7C9C5 40%, #D6E2F3 80%, $paleblue) 1
		pointer-events none

		~/[modal] &
			border-image linear-gradient(transparent, transparent) 1

	&__header
		position fixed
		top 0
		z-index 1000
		display flex
		justify-content space-between
		width 100%
		text-align left
		line-height 2.2rem

	&__title
		padding 0.3rem 0 0 0.3rem
		user-select none
		--color black

		+mobile()
			font-size 0.85rem

		~/[modal=info] &
			--color $paleblue

		~/[modal=image] &
			--color transparent

		h1, h2
			transition all $modal-dur ease

		h1
			margin 0
			color transparent
			font-size 2.5em
			-webkit-text-stroke 0.1px var(--color)
			text-stroke 0.1px var(--color)

			+mobile()
				-webkit-text-stroke 0.5px var(--color)
				text-stroke 0.5px var(--color)

		h2
			margin 0
			color var(--color)
			letter-spacing 0.15em
			font-weight normal
			font-size 1.8em

	&__menu
		display flex
		margin 2rem 2rem 0 0
		height 2rem

		+mobile()
			margin 1.5rem 1.5rem 0 0

		a
			padding 0 1em
			width 4em
			border-radius 1rem
			background $redpink
			text-align center
			cursor pointer
			user-select none

			&:before
				content '?'

			~/[modal] &
				background $paleblue

				&:before
					content 'X'

			&:hover
				color white
				animate-bg()

	&__content
		padding var(--frame-width)

	&__filters
		display none

	&__modal
		position fixed
		top 0
		left 0
		z-index 100
		display flex
		visibility hidden
		flex-direction column
		justify-content center
		width 100%
		height 100%
		color black
		opacity 0
		transition opacity $modal-dur ease, visibility 0s linear $modal-dur

		&[modal]
			visibility visible
			opacity 1
			transition opacity $modal-dur ease, visibility 0s linear 0s

		&[modal=info]
			backdrop-filter url('#duotone-raddish')

		&[modal=image]
			cursor zoom-out
			backdrop-filter url('#duotone-black')

		&.disable-svg-filter
			backdrop-filter none

			&[modal=info]
				background transparentify($redpink, 0.8)

			&[modal=image]
				background rgba(0, 0, 0, 0.3)
				backdrop-filter invert() grayscale()

		&-content
			position relative
			max-width 100%
			max-height 100%
			text-align center

		p
			margin 1em 0

		a
			background $paleblue
			color $redpink

			&:hover
				animate-bg()
				color white

		img
			display block
			width 100%
			height 100%
			object-fit contain
</style>
