
import {templateRef, useCssVar} from '@vueuse/core'
import BezierEasing from 'bezier-easing'
import yaml from 'js-yaml'
import {computed, defineComponent, ref, watchEffect} from 'vue'

interface GalleryImage {
	src: string
	size: [number, number]
	layout?: 'small' | 'center' | 'large' | 'full'
}

export default defineComponent({
	name: 'Gallery',
	setup() {
		const root = templateRef('root')
		const gap = useCssVar('--gap', root)
		const imageScale = useCssVar('--img-scale', root)

		const gallery = ref<GalleryImage[]>([])

		const randomEasing = BezierEasing(0.4, 0, 0.6, 1)

		watchEffect(() => console.log(imageScale.value))

		fetch('/gallery.yml').then(async res => {
			gallery.value = yaml.loadAll(await res.text())[0].gallery
		})

		const galleryStyle = computed(() =>
			gallery.value.map(() => ({
				'--x': randomEasing(Math.random()),
				//marginLeft: Math.random() * (100 - parseFloat(imageScale.value)) + '%',
				animationDelay: Math.random() * -4 + 's',
			}))
		)

		function onImgLoaded(e: Event) {
			const el = e.target as HTMLImageElement
			if (el.src.startsWith('data')) return
			el.classList.add('loaded')
		}

		return {gallery, galleryStyle, gap, onImgLoaded}
	},
})
