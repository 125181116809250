
import 'normalize.css'

import {templateRef} from '@vueuse/core'
import {defineComponent, ref} from 'vue'

import Gallery from './components/Gallery.vue'

function isMobileSafari() {
	return !!/iPad|iPhone/i.test(window.navigator.userAgent)
}

interface ModalInfo {
	type: 'info'
}

interface ModalImage {
	type: 'image'
	src: string
}

type ModalContent = null | ModalInfo | ModalImage

export default defineComponent({
	name: 'App',
	components: {Gallery},
	setup() {
		const disableSVGFilter = ref(isMobileSafari())

		const modal = ref<ModalContent>(null)

		const modalEl = templateRef('modalEl')

		function onClickMenu() {
			modal.value = modal.value ? null : {type: 'info'}
		}

		function onClickModal(e: Event) {
			if (!modal.value) return

			if (modal.value.type === 'info') {
				if (e.target === modalEl.value) {
					modal.value = null
				}
			} else {
				modal.value = null
			}
		}

		function popupImage(src: string) {
			modal.value = {type: 'image', src}
		}

		return {
			disableSVGFilter,
			modal,
			onClickMenu,
			onClickModal,
			popupImage,
		}
	},
})
